import 'what-input';
import 'jquery-ujs';
import $ from 'jquery';

import 'src/libs/jquery.scrollTo.js';
import 'src/parts/header.js';
import 'src/parts/tm_panel.js';
import 'src/parts/ga_events.js';
import 'src/components/overlays/BasicOverlay/BasicOverlay.js';
import 'src/components/BasicDropdown/BasicDropdown.js';
import {
  // eslint-disable-next-line camelcase
  clear_form_errors,
  // eslint-disable-next-line camelcase
  scroll_to_first_error,
  trackEvent,
  trackEventByName,
} from 'src/global-helpers.js';

import 'src/components/PageWidth/PageWidth.sass';
import 'src/components/BasicAvatar/BasicAvatar.sass';
import 'src/components/BasicLink/BasicLink.sass';
import 'src/components/BasicButton/BasicButton.sass';
import 'src/components/SectionBox/SectionBox.sass';
import 'src/components/SocialLinks/SocialLinks.sass';
import 'src/components/icons/SvgIcon/SvgIcon.sass';
import 'src/components/badges/CounterBadge/CounterBadge.sass';
import 'src/components/placeholders/NoContent/NoContent.sass';
import 'src/components/overlays/BasicOverlay/BasicOverlay.sass';
import 'src/components/overlays/ModalWindow/ModalWindow.sass';
import 'src/components/sections/BasicSection/BasicSection.sass';
import 'src/components/header/BurgerOverlay/BurgerOverlay.sass';
import 'src/components/header/UserAuthMenu/UserAuthMenu.sass';
import 'src/components/header/CompanySwitcherModal/CompanySwitcherModal.sass';
import 'src/components/header/UserNotifications/UserNotifications.sass';
import 'src/components/header/FriendshipRequests/FriendshipRequests.sass';
import 'src/components/MenuHead/MenuHead.sass';
import 'src/components/MenuSection/MenuSection.sass';

import 'styles/libs/normalize.css';
import 'styles/globals.sass';
import 'styles/parts/tm_panel.sass';
import 'styles/parts/buttons.css.sass';
import 'styles/views/layouts/header.sass';
import 'styles/views/layouts/_footer.css.sass';
import 'styles/views/layouts/user_panel.sass';

window.$ = $;

window.helpers = {
  clear_form_errors,
  scroll_to_first_error,
  trackEvent,
  trackEventByName,
};
